import React, { ReactElement } from 'react';
import Title from './Title';

interface Props {
  title: string;
}

function Page({ title, children }: React.PropsWithChildren<Props>): ReactElement {
  return (
    <div className="px-6 sm:px-8 lg:px-10 py-6 max-w-7xl mx-auto">
      <Title className="mb-4">{title}</Title>
      {children}
    </div>
  );
}

export default Page;
