import React, { Fragment } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { HiMenu, HiX } from 'react-icons/hi';
import classNames from 'classnames';
import VoyanticLogo from '../assets/voyantic-logo-full-color-rgb-hz.png';
import VoyanticLogoSmall from '../assets/voyantic-logo-mark-full-color-rgb-with-margin-48x48.png';
import { NavLink } from 'react-router-dom';
import { User } from '../data/User';

const navItems: { name: string; url: string }[] = [{ name: 'Applications', url: '/' }];
const profileItems: { name: string; url: string }[] = [];

interface Props {
  user: User | undefined;
  onSignOut: () => void;
}

export default function Layout({ user, onSignOut, children }: React.PropsWithChildren<Props>) {
  return (
    <>
      <Disclosure as="nav" className="bg-white shadow">
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex justify-between h-16">
                <div className="flex">
                  <div className="flex-shrink-0 flex items-center">
                    <img className="hidden sm:block md:hidden h-12 w-auto" src={VoyanticLogoSmall} alt="Voyantic" />
                    <img className="block sm:hidden md:block h-10 w-auto" src={VoyanticLogo} alt="Voyantic" />
                  </div>
                  <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                    {navItems.map((navItem) => (
                      <NavLink
                        key={navItem.name}
                        to={navItem.url}
                        className={({ isActive }) =>
                          isActive
                            ? 'border-gray-dark text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-base font-medium'
                            : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-base font-medium'
                        }
                      >
                        {navItem.name}
                      </NavLink>
                    ))}
                  </div>
                </div>
                <div className="hidden sm:ml-6 sm:flex sm:items-center">
                  {/* Profile dropdown */}
                  {user && (
                    <Menu as="div" className="ml-3 relative">
                      <div>
                        <Menu.Button className="flex items-center space-x-2 bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-dark">
                          <span className="sr-only">Open user menu</span>
                          <div className="h-8 w-8 rounded-full bg-gray-200" />
                          <div className="text-sm font-bold text-gray-800">{user.name}</div>
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {profileItems.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <a
                                  href={item.url}
                                  className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block px-4 py-2 text-sm text-gray-700',
                                  )}
                                >
                                  {item.name}
                                </a>
                              )}
                            </Menu.Item>
                          ))}
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                onClick={onSignOut}
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block px-4 py-2 text-sm text-gray-700 w-full text-left',
                                )}
                              >
                                Sign out
                              </button>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  )}
                </div>
                <div className="-mr-2 flex items-center sm:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-dark">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <HiX className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <HiMenu className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="pt-2 pb-3 space-y-1">
                {navItems.map((navItem) => (
                  <Disclosure.Button key={navItem.name} className="w-full">
                    <NavLink
                      to={navItem.url}
                      className={({ isActive }) =>
                        isActive
                          ? 'bg-gray-50 border-gray-dark text-gray-dark block pl-3 pr-4 py-2 border-l-4 text-base font-medium text-left'
                          : 'border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium text-left'
                      }
                    >
                      {navItem.name}
                    </NavLink>
                  </Disclosure.Button>
                ))}
              </div>
              {user && (
                <div className="pt-4 pb-3 border-t border-gray-200">
                  <div className="flex items-center px-4">
                    <div className="flex-shrink-0">
                      <div className="h-10 w-10 rounded-full bg-gray-200" />
                    </div>
                    <div className="ml-3">
                      <div className="text-base font-medium text-gray-800">{user.name}</div>
                      <div className="text-sm font-medium text-gray-500">{user.email}</div>
                    </div>
                  </div>
                  <div className="mt-3 space-y-1">
                    {profileItems.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        as="a"
                        href={item.url}
                        className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))}
                    <Disclosure.Button
                      as="button"
                      onClick={onSignOut}
                      className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                    >
                      Sign out
                    </Disclosure.Button>
                  </div>
                </div>
              )}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      {children}
    </>
  );
}
