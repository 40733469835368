import React from 'react';
import { Application } from '../../../data/Application';
import ApplicationListItem from './ApplicationListItem';

interface Props {
  applications: Application[];
  loading: boolean;
  error: boolean;
}

export default function ApplicationsList({ applications, loading, error }: Props) {
  return (
    <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
      {loading && 'Loading...'}
      {error && 'Failed to load applications.'}
      {applications.map((app) => (
        <ApplicationListItem key={app.name} application={app} />
      ))}
    </ul>
  );
}
