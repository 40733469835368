import React, { ReactElement, useContext } from 'react';
import Page from '../../components/Page';
import ApplicationsList from './components/ApplicationsList';
import { Auth } from 'aws-amplify';
import { Application } from '../../data/Application';
import { useQuery } from 'react-query';
import { ConfigContext } from '../../config';

function Applications(): ReactElement {
  const config = useContext(ConfigContext);

  const { data, isLoading, error } = useQuery<Application[]>('applications', async () => {
    const accessToken = (await Auth.currentSession()).getAccessToken().getJwtToken();
    return fetch(`${config.apiUrl}/api/v1/app-clients`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    }).then((x) => x.json());
  });

  return (
    <Page title="Applications">
      <ApplicationsList applications={data ?? []} loading={isLoading} error={!!error} />
    </Page>
  );
}

export default Applications;
