import React, { ReactElement } from 'react';
import { Auth } from 'aws-amplify';
import Page from '../../components/Page';

interface Props {
  loading: boolean;
}

function LoggedOut({ loading }: Props): ReactElement {
  return (
    <Page title="Applications">
      {!loading && (
        <button
          className="voyantic-button"
          onClick={() => {
            Auth.federatedSignIn();
          }}
        >
          Sign in
        </button>
      )}
      {loading && 'Loading...'}
    </Page>
  );
}

export default LoggedOut;
