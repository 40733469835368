import React, { ReactElement } from 'react';
import classNames from 'classnames';

interface Props {
  className?: string;
}

function Title({ className, children }: React.PropsWithChildren<Props>): ReactElement {
  return <h1 className={classNames('text-2xl text-gray-dark font-bold', className)}>{children}</h1>;
}

export default Title;
