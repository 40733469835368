import React, { ReactElement } from 'react';
import { Application } from '../../../data/Application';

interface Props {
  application: Application;
}

function ApplicationListItem({ application: { name, description, url } }: Props): ReactElement {
  return (
    <li className="col-span-1 flex flex-col bg-white rounded-lg shadow divide-y divide-gray-200">
      <div className="w-full flex flex-1 items-start justify-between p-6 space-x-6">
        <div className="flex-1">
          <div className="flex items-center space-x-3">
            <h3 className="text-gray-dark text-base font-bold truncate">{name}</h3>
          </div>
          <p className="mt-1 text-gray-500 text-sm">{description}</p>
        </div>
        <img className="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0" alt="" />
      </div>

      <div className="-mt-px flex">
        <div className="-ml-px w-0 flex-1 flex">
          <a
            href={url}
            className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
          >
            Launch
          </a>
        </div>
      </div>
    </li>
  );
}

export default ApplicationListItem;
