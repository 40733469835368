import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import Layout from './components/Layout';
import Applications from './pages/applications/Applications';
import { LoginState } from './data/LoginState';
import LoggedOut from './pages/logged-out/LoggedOut';
import { Config, ConfigContext } from './config';

const queryClient = new QueryClient();

function App() {
  const [loginState, setLoginState] = useState<LoginState>({ type: 'Loading' });
  const [config, setConfig] = useState<Config>({ apiUrl: '/' });

  useEffect(() => {
    const initialize = async () => {
      try {
        const conf = await fetch('config.json').then((res) => res.json());
        setConfig({ apiUrl: conf.apiUrl });
        conf.aws.Auth.cookieStorage.secure = location.hostname !== 'localhost';
        Auth.configure(conf.aws.Auth);

        const cognitoUser = await Auth.currentAuthenticatedUser();
        const idToken = cognitoUser.getSignInUserSession()?.getIdToken();

        if (idToken) {
          const givenName = idToken.payload['given_name'];
          const familyName = idToken.payload['family_name'];
          setLoginState({
            type: 'LoggedIn',
            user: {
              name: `${givenName} ${familyName}`,
              email: idToken.payload['email'],
            },
          });
        } else {
          setLoginState({ type: 'LoggedOut' });
        }
      } catch (e) {
        setLoginState({ type: 'LoggedOut' });
      }
    };

    initialize();
  }, [setLoginState, setConfig]);

  return (
    <QueryClientProvider client={queryClient}>
      <ConfigContext.Provider value={config}>
        <Router>
          <Layout
            user={loginState.type === 'LoggedIn' ? loginState.user : undefined}
            onSignOut={() => {
              Auth.signOut();
            }}
          >
            {loginState.type !== 'LoggedIn' && <LoggedOut loading={loginState.type === 'Loading'} />}
            {loginState.type === 'LoggedIn' && (
              <Routes>
                <Route path="/" element={<Applications />} />
                <Route path="*" element={<Navigate replace to="/" />} />
              </Routes>
            )}
          </Layout>
        </Router>
      </ConfigContext.Provider>
    </QueryClientProvider>
  );
}

export default App;
